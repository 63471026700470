import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pagesContainer", "tabsContainer"]

  showTab(event) {
    const tab = event.currentTarget.dataset.tab

    if (event.currentTarget.classList.contains('w--current')) {
      return
    } else {
      Array.from(this.tabsContainerTarget.children).forEach((container) => {
        if (container.dataset.tab !== tab) {
          container.classList.remove('w--current')
        }
      })
      event.currentTarget.classList.add('w--current')

      Array.from(this.pagesContainerTarget.children).forEach((container) => {
        if (container.dataset.page === tab) {
          container.classList.add('w--tab-active')
        } else {
          container.classList.remove('w--tab-active')
        }
      })
    }
  }
}
