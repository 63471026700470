import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "form", "heading", "submit", "container", "subheading", "submitText"]

  open(event) {
    event.stopPropagation()

    this.wrapperTarget.classList.add('cstm-flex')
  }

  close() {
    this.wrapperTarget.classList.remove('cstm-flex')
  }

  closeOnOuterClick(event) {
    if (event.target == this.wrapperTarget) {
      this.close()
    }
  }
}
