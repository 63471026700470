import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import isEmpty from 'lodash.isempty'

export default class extends Controller {
  static values = { url: String }
  static targets = ["submit", "slot_0", "slot_1", "slot_2", "input", "imageSizeError", "remove", "spinner"]

  changed(event) {
    // clear any validation errors on fresh upload
    this.imageSizeErrorTarget.classList.add('hidden')
    this.enableSubmit()

    // if they try to upload more than three files, show an error, disable submit, and eject
    if (this.inputTarget.files.length > 3) {
      this.imageSizeErrorTarget.classList.remove('hidden')
      this.disableSubmit()
      return
    }

    // find out how many existing files there are, by having a hidden input with where the value is present
    // figure out what slot numbers are in use by those existing files
    const slots = this.element.querySelectorAll('[data-direct-uploads-target^="slot"]')
    const existingOnes = Array.from(slots).filter((slot) => {
      return !isEmpty(slot.querySelector('input[type="hidden"]').value)
    })

    // figure out how many open slots are left
    // figure out what the slot numbers are for the open slots, and sort slots numbers in ascending order
    const availableSlots = Array
      .from(slots)
      .filter((slot) => {
        return isEmpty(slot.querySelector('input[type="hidden"]').value)
      })
      .sort((a, b) => {
        return a.attributes.getNamedItem('data-direct-uploads-index').value - b.attributes.getNamedItem('data-direct-uploads-index').value
      })

    // if they upload more files than there are open slots, show an error and eject
    if (this.inputTarget.files.length > availableSlots.length) {
      // console.log(this.imageSizeErrorTarget);
      this.imageSizeErrorTarget.classList.remove('hidden')
      this.disableSubmit()
      return
    }

    Array.from(this.inputTarget.files).forEach((file, index) => {
      // loop over the uploaded files, and for each one:
      // find the first open slot
      const slot = availableSlots[index]

      // show that the file is uploading
      const removeButton = slot.querySelector('div[data-direct-uploads-target="remove"]')
      const loadingSpinner = slot.querySelector('div[data-direct-uploads-target="spinner"]')
      loadingSpinner.classList.remove('hidden')
      loadingSpinner.classList.add('cstm-block')

      const upload = new DirectUpload(file, this.urlValue)
      upload.create((error, blob) => {
        console.error(error)

        // set the slot's img.src to the blob url
        const imageElement = slot.querySelector('img[data-direct-uploads-target="image"]')
        imageElement.src = `${this.redirectPath}/${blob.signed_id}/${blob.filename}`

        // find the hidden input target element, setting the value to the blob signed id, and the correct input.name
        const hiddenInput = slot.querySelector('input[type="hidden"]')
        hiddenInput.value = blob.signed_id

        // hide the loading spinner and show remove button
        setTimeout(() => {
          removeButton.classList.remove('hidden')
          loadingSpinner.classList.add('hidden')
          loadingSpinner.classList.remove('cstm-block')
        }, 2000);
      })
    })
  }

  get redirectPath() {
    return '/rails/active_storage/blobs/redirect'
  }

  disableSubmit() {
    this.submitTarget.disabled = true
  }

  /**
   * Prevents submitting the form by hitting the "enter" key. Specifically if a user is typing in the
   * Giphy search input and hits enter, it will not inadvertently submit the form.
   *
   * @see https://stimulus.hotwired.dev/reference/actions#keyboardevent-filter
   */
  preventSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }

  enableSubmit() {
    this.submitTarget.disabled = false
    this.submitTarget.classList.remove('inactive')
  }

  onSubmit() {
    this.submitTarget.classList.add('inactive')

    setTimeout(() => {
      this.disableSubmit()
    }, 250); // milliseconds
  }
}
