import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import getCsrfToken from "../utils/csrfUtils"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      animation: 150,
      onEnd: this.end.bind(this),
    })
  }

  end(event) {
    const id = event.item.dataset.id
    const data = new FormData()
    const url = this.data.get("url").replace(":id", id)
    data.append("position", event.newIndex + 1)

    fetch(url, {
      method: "PATCH",
      headers: { "X-CSRF-Token": getCsrfToken() },
      credentials: "same-origin",
      body: data,
    }).then((response) => {
      if (response.status < 300) {
        // console.log("success", response.status)
      } else {
        console.error(response)
      }
    })
  }
}
