import { Controller } from "@hotwired/stimulus"
/*
  * @see https://atomiks.github.io/tippyjs/
  * @see https://classic.yarnpkg.com/en/package/tippy.js
  * @see https://thoughtbot.com/blog/taking-the-most-out-of-stimulus
*/
import tippy from "tippy.js"
import 'tippy.js/dist/tippy.css'

export default class extends Controller {
  static values = { message: String }; // We could receive a config object here, to customize the tooltip

  connect() {
    this.tippyInstance = tippy(
      this.element,
      {
        content: this.messageValue,
        theme: 'light'
      }
    );
  }

  disconnect() {
    this.tippyInstance.destroy();
  }
}
