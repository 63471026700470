import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameSubmit", "passwordSubmit"]

  changeName() {
    this.enableNameSubmit()
  }

  enableNameSubmit() {
    this.nameSubmitTarget.disabled = false
    this.nameSubmitTarget.classList.remove('inactive')
  }

  changePassword() {
    this.enablePasswordSubmit()
  }

  enablePasswordSubmit() {
    this.passwordSubmitTarget.disabled = false
    this.passwordSubmitTarget.classList.remove('inactive')
  }
}
