import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navbar", "outerDiv"]

  toggleMenu(event) {
    event.stopPropagation()

    this.navbarTarget.classList.toggle('cstm-side-nav-open')
    this.outerDivTarget.classList.toggle('cstm-menu-open')
  }
}
