import { Controller } from "@hotwired/stimulus"
import { debounce, throttle } from "throttle-debounce"
import { renderGrid } from "@giphy/js-components"
import { GiphyFetch } from "@giphy/js-fetch-api"

/*
  @see https://github.com/Giphy/giphy-js/blob/master/packages/components/README.md#grid
  @see https://github.com/Giphy/giphy-js/tree/master/packages/fetch-api
*/
export default class extends Controller {
  static targets = ["hiddenFormField", "giphyContainer", "selectedGiphy", "term", "hiddenGifValue", "removeGifIcon", "previewContainer"]

  initialize() {
    this.grid = this.makeGrid()
  }

  /*
    Create an instance of GiphyFetch
  */
  get giphyFetch() {
    return new GiphyFetch(import.meta.env.VITE_GIPHY_API_KEY)
  }

  onTermChange() {
    debounce(1000, () => {
      this.grid.remove()
      this.grid = this.makeGrid()
    })()
  }

  /*
    Fetch trending gifs with an offset. This allows the grid to paginate as the user scrolls.
    Use any endpoint, but be sure to pass offset to paginate correctly. For endpoint options
    @see https://github.com/Giphy/giphy-js/tree/master/packages/fetch-api#trending
  */
  fetchTrending(offset) {
    return this.giphyFetch.trending({ offset, limit: 25 })
  }

  /*
    Search all Giphy GIFs for a word or phrase, with an offset.
    @see https://github.com/Giphy/giphy-js/tree/master/packages/fetch-api#search
  */
  fetchSearch(offset, term) {
    return this.giphyFetch.search(term, {
      sort: "relevant",
      lang: "es",
      limit: 25,
      offset,
    })
  }

  setGiphyParams = (gif) => {
    let giphyParams = {
      giphy_id: gif.id,
      original_gif: gif.images.original.url,
      original_webp: gif.images.original.webp,
      original_mp4: gif.images.original.mp4,
      preview_gif: gif.images.preview_gif.url,
      preview_webp: gif.images.preview_webp.url,
    }

    this.hiddenFormFieldTarget.value = JSON.stringify(giphyParams)
  }

  setSelectedGiphy = (gif) => {
    this.selectedGiphyTarget.src = gif.images.original.url
  }

  showRemoveGifIcon = () => {
    this.removeGifIconTarget.classList.remove('hidden')
  }

  nullifyHiddenGifValue = () => {
    this.hiddenGifValueTarget.value = null
  }

  blackGifBackground = () => {
    this.previewContainerTarget.classList.remove('cstm-background-lite')
  }

  /*
    A Gif event for onClick.  For all Gif events see:
    https://github.com/Giphy/giphy-js/blob/master/packages/components/README.md#gif-events
  */
  onGifClick = (gif, event) => {
    event.preventDefault()
    this.setSelectedGiphy(gif)
    this.showRemoveGifIcon()
    this.nullifyHiddenGifValue()
    this.setGiphyParams(gif)
    this.enableSubmitButton()
    this.blackGifBackground()
  }

  makeGrid() {
    const render = () => {
      let containerWidth = this.giphyContainerTarget.offsetWidth
      let fetchEndpoint = this.termTarget.value
        ? (offset) => this.fetchSearch(offset, this.termTarget.value)
        : (offset) => this.fetchTrending(offset)

      return renderGrid(
        {
          width: containerWidth,
          fetchGifs: fetchEndpoint,
          columns: 2,
          gutter: 6,
          onGifClick: this.onGifClick,
          hideAttribution: true
        },
        this.giphyContainerTarget
      )
    }

    const resizeRender = throttle(500, render)
    window.addEventListener("resize", resizeRender, false)
    const remove = render()

    return {
      remove: () => {
        remove()
        window.removeEventListener("resize", resizeRender, false)
      },
    }
  }

  enableSubmitButton() {
    const submitButton = document.getElementById("post-edit-form-submit-button")

    submitButton.disabled = false
    submitButton.classList.remove('inactive')
  }

  removeGif(event) {
    const placeholderImage = event.target.closest('div[data-images-placeholder-value]').dataset.imagesPlaceholderValue

    this.selectedGiphyTarget.src = placeholderImage
    this.hiddenGifValueTarget.value = 'true'
    this.removeGifIconTarget.classList.add('hidden')
    this.hiddenFormFieldTarget.value = null
    this.previewContainerTarget.classList.add('cstm-background-lite')
    this.enableSubmitButton()
  }

  disconnect() {
    this.grid.remove()
  }
}
