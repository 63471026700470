import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { placeholder: String }

  enableSubmitButton() {
    const submitButton = document.getElementById("post-edit-form-submit-button")

    submitButton.disabled = false
    submitButton.classList.remove('inactive')
  }

  removeImage(event) {
    const container = event.target.parentElement

    container.parentElement.querySelector('img').src = this.placeholderValue
    container.querySelector('input[type="hidden"]').value = null
    container.classList.add('hidden')
    this.enableSubmitButton()
  }
}
