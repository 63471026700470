import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameSubmit"]

  changeName() {
    this.enableNameSubmit()
  }

  enableNameSubmit() {
    this.nameSubmitTarget.disabled = false
    this.nameSubmitTarget.classList.remove('inactive')
  }
}
