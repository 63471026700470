import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["teamsInput", "currentTeamContainer", "noTeamContainer", "form", "actionsContainer", "threeDots", "actionsNav", "nameInput", "nameHeading", "teamRow", "memberCheckBox"]

  /**
   *
   * @see https://stimulus.hotwired.dev/reference/outlets
   */
  static outlets = ["modal"]

  closeOtherModals(modalId) {
    const otherModals = this.modalOutlet.containerTargets.filter((wrapper) => wrapper.dataset.id !== modalId)

    otherModals.forEach((form) => {
      form.classList.add("hidden")
    })
  }


  createAction(event) {
    event.preventDefault()
    this.hideActions()

    if (this.hasModalOutlet) {
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "createTeamForm")

      this.closeOtherModals("createTeamForm")

      container.querySelector("[data-teams-target='nameInput']").value = null
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  editAction(event) {
    event.preventDefault()
    this.hideActions()

    if (this.hasModalOutlet) {
      const team = event.target.closest("[data-teams-target='teamRow']")
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "editTeamForm")

      this.closeOtherModals("editTeamForm")

      container.querySelector("[data-teams-target='nameHeading']").innerText = team.dataset.name
      container.querySelector("[data-teams-target='nameInput']").value = team.dataset.name
      container.querySelector("[data-teams-target='form']").action = `/teams/${team.dataset.id}`
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  deleteAction(event) {
    event.preventDefault()
    this.hideActions()

    if (this.hasModalOutlet) {
      const team = event.target.closest("[data-teams-target='teamRow']")
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "deleteTeamForm")

      this.closeOtherModals("deleteTeamForm")

      container.querySelector("[data-teams-target='nameHeading']").innerText = team.dataset.name
      container.querySelector("[data-teams-target='form']").action = `/teams/${team.dataset.id}`
      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    } else {
      console.log('no modal outlet')
    }
  }

  movePeopleConfirmation(event) {
    event.preventDefault()

    const selectedMembersCount = this.memberCheckBoxTargets.filter((checkbox) => checkbox.checked === true).length

    if (selectedMembersCount === 0) {
      return
    }

    const selectedIndex = this.teamsInputTarget.options.selectedIndex
    const selectedChannel = this.teamsInputTarget.options[selectedIndex].innerText

    if (this.hasModalOutlet) {
      const isAMotiChannel = selectedChannel.includes('moti')
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "movePeopleConfirmation")

      this.closeOtherModals("movePeopleConfirmation")

      const headerText = isAMotiChannel ?
        `Add team members to ${selectedChannel}?` :
        "Remove team members from Moti?"

      const subheadingText = isAMotiChannel ?
        `Once confirmed, Moti will add the ${selectedMembersCount} selected team member(s) to the ${selectedChannel} Slack channel in your Slack Workspace.` :
        `The ${selectedMembersCount} selected team member(s) will remain in the Slack channel in your Slack Workspace; however, they will no longer be on a Moti team and will no longer receive any weekly Moti's.`

      const submitText = isAMotiChannel ? "Add Members To Channel" : "Remove Members from Moti"
      const buttonSpinnerText = isAMotiChannel ? "Adding Members..." : "Removing Members..."

      container.querySelector("[data-modal-target='heading']").innerText = headerText
      container.querySelector("[data-modal-target='subheading']").innerText = subheadingText
      container.querySelector("[data-modal-target='submitText']").value = submitText
      container.querySelector("[data-modal-target='submitText']").dataset.turboSubmitsWith = buttonSpinnerText

      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    }
  }

  refreshMembersConfirmation(event) {
    event.preventDefault()

    if (this.hasModalOutlet) {
      const container = this.modalOutlet.containerTargets.find((wrapper) => wrapper.dataset.id === "refreshMembersConfirmation")

      container.classList.remove("hidden")
      this.modalOutlet.open(event)
    }
  }


  canOnlySelectMembersFrom(selectedTeam) {
    const currentTeamCheckboxes = this.currentTeamContainerTarget.querySelectorAll(".currentTeam")
    const noTeamCheckboxes = this.noTeamContainerTarget.querySelectorAll(".noTeam")

    const selectedTeamCollection = selectedTeam === "currentTeam" ? currentTeamCheckboxes : noTeamCheckboxes
    const nonSelectedTeamCollection = selectedTeam === "currentTeam" ? noTeamCheckboxes : currentTeamCheckboxes

    const anySelectedTeamMembersChecked = Array.from(selectedTeamCollection).some((checkbox) => checkbox.checked === true)

    if (anySelectedTeamMembersChecked) {
      nonSelectedTeamCollection.forEach((input) => {
        input.disabled = true
        input.closest(".team-member").classList.add("cstm-inactive-cursor")
      })
    } else {
      nonSelectedTeamCollection.forEach((input) => {
        input.disabled = false
        input.closest(".team-member").classList.remove("cstm-inactive-cursor")
      })
    }
  }

  memberClick(event) {
    event.stopPropagation()

    if (event.target.classList.contains("currentTeam")) {
      this.canOnlySelectMembersFrom("currentTeam")
    } else if (event.target.classList.contains("noTeam")) {
      this.canOnlySelectMembersFrom("noTeam")
    }
  }

  clickRow(event) {
    Turbo.visit(event.currentTarget.dataset.path)
  }

  openActionsMenu(event) {
    event.stopPropagation()
    this.hideActions()
    const actionsContainer = event.target.closest("[data-teams-target='actionsContainer']")
    actionsContainer.querySelector("[data-teams-target='threeDots']").classList.add("w--open")
    actionsContainer.querySelector("[data-teams-target='actionsNav']").classList.add("w--open")
  }

  hideActions() {
    this.threeDotsTargets.forEach((threeDots) => {
      if (threeDots.classList.contains("w--open")) {
        threeDots.classList.remove("w--open")
      }
    })
    this.actionsNavTargets.forEach((actionsNav) => {
      if (actionsNav.classList.contains("w--open")) {
        actionsNav.classList.remove("w--open")
      }
    })
  }
}
