/**
 * @see https://www.stimulus-components.com/docs/stimulus-notification
 */
import Notification from 'stimulus-notification'

export default class extends Notification {
  connect() {
    super.connect()
  }
}
