import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]

  show() {
    this.spinnerTarget.style.display = 'block'
  }
}

