import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["channelSubmit", "timeSubmit"]

  onChannelSelect() {
    this.enableChannelSubmit()
  }

  enableChannelSubmit() {
    this.channelSubmitTarget.disabled = false
    this.channelSubmitTarget.classList.remove('inactive')
  }

  onTimeSelect() {
    this.enableTimeSubmit()
  }

  enableTimeSubmit() {
    this.timeSubmitTarget.disabled = false
    this.timeSubmitTarget.classList.remove('inactive')
  }
}
